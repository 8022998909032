import React from "react"
import "../styles/privacy.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import JobForm2 from "../components/job-application-form2"

const AutomationEngineer = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO title="Automation DevOps Engineer" />
    <div className="job-vac-section">
      <div className="eng-chal-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Automation DevOps Engineer</h1>
                <h2>Job Vacancy Information and Application Form</h2>
                <h3>Contact us and send us your CV for review.</h3>
                {/* <ButtonB href="/about">Our Story</ButtonB> */}
              </div>
            </div>

            <div className="outer-container">
              <div className="inner-container" style={{ textAlign: "left" }}>
                <div style={{ width: "100%" }}>
                  <h3>Job Title</h3>
                  <div className="aut-div">
                    <p>Automation DevOps Engineer</p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Department</h3>
                  <div className="aut-div">
                    <p>Service Orchestration, DevOps Team</p>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <h3>Location</h3>
                  <div className="aut-div">
                    <p>
                      Flexible - working from home, office or on customer sites.
                    </p>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <h3>Salary Range</h3>
                  <div className="aut-div">
                    <p>Commensurate with experience.</p>
                  </div>
                </div>

                <div>
                  <h3>Role Summary</h3>
                  <div className="aut-div">
                    <p>
                      This is an amazing opportunity for a passionate IT
                      generalist who should have a wide-ranging knowledge of
                      both development and operations, including scripting and
                      coding, infrastructure management, system administration
                      and automation toolchains. You don't need to know it all,
                      we can teach you, but you need to be self-motivated and
                      comfortable in a start-up culture!{" "}
                    </p>
                  </div>
                </div>

                <div>
                  <h3>Duties and Responsibilities:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        • Custom script and code development for infrastructure
                        and application deployment automation, particularly in
                        modern data centre and private cloud environments
                      </li>

                      <li>
                        • Solution development around automated software
                        lifecycle and release management
                      </li>
                      <li>
                        • Public and private cloud platform integrations with
                        customer's exiting operational and business systems
                      </li>
                      <li>
                        • Building Proof of Solutions / Proof of Value
                        demonstrations to customer requirements{" "}
                      </li>

                      <li>
                        • Acting in the role of Technical Lead on customer
                        engagements where needed, taking responsibility for
                        customer requirements and project deliverables
                      </li>
                      <li>
                        • New technology assessment and outcome presentation to
                        peers, with a view to becoming a subject matter expert
                      </li>

                      <li>
                        {" "}
                        • Collaboratively building innovative Metsi solutions
                        and developing associated webinars, blog content and
                        whitepapers{" "}
                      </li>
                      <li>
                        {" "}
                        • Documentation of customer solutions, process and
                        knowledge basis{" "}
                      </li>
                    </ul>
                  </div>
                </div>

                <div style={{ textAlign: "left", width:"100%" }}>
                  <div>
                    <br />
                    <h3>Competencies:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          • Good knowledge of a scripting language and API
                          integrations, ideally deep skills in one language
                          (Python would be an advantage)
                        </li>

                        <li>
                          • Good foundation of coding skills and understanding
                          of software lifecycles and release management{" "}
                        </li>
                        <li>
                          • Familiarity with infrastructure-as-code and
                          infrastructure automation, especially Terraform{" "}
                        </li>
                        <li>
                          • Experience with containers and container frameworks
                          advantageous
                        </li>

                        <li>
                          • Knowledge of 1 or more public cloud providers
                          solutions, tools and APIs beneficial{" "}
                        </li>
                        <li>
                          • Self-starter and able to work autonomously when
                          needed
                        </li>

                        <li>
                          {" "}
                          • Good people and communications skills - you will be
                          in front of customers{" "}
                        </li>
                        <li>
                          {" "}
                          • A structured approach to problem solving and
                          solution management{" "}
                        </li>
                        <li>
                          {" "}
                          • Experience of working in a start-up environment or
                          culture an advantage{" "}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <div style={{ textAlign: "left" }}>
                      <br />
                      <h3>About Metsi</h3>
                      <div
                        className="aut-div"
                      >
                        <p>
                          We are a fast-moving organisation at the forefront of
                          driving digital maturity in this multi-cloud world.
                          End-to-end automation is everything to us, and our
                          Engineering Team is core to building intelligent
                          solutions that drive innovation and business agility
                          for our customers. With a global presence and an
                          emphasis on employee work-life balance, Metsi provides
                          great opportunity for travel and flexible working
                          arrangement, as well as personal development and
                          growth.{" "}
                        </p>
                      </div>
                      <h3>Apply Now:</h3>
                      <JobForm2 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AutomationEngineer
